<template>
    <v-container>
        <v-row justify="center" class="pt-5">
            <!-- <h1 class="text-h4 font-weight-light" v-show="isActivatingLoginShield">Organization</h1>
            <h1 class="text-h4 font-weight-light" v-show="!isActivatingLoginShield">Login</h1> -->
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card class="px-10 py-5 pt-10 mt-5" v-if="!isViewReady">
                    <p>Please wait...</p>
                </v-card>
                <v-card class="px-10 py-5 pt-10 mt-5" v-if="isViewReady && error">
                    <p class="red--text">Service unavailable. Please try again later.</p>
                </v-card>
                <!-- TODO: for loginfront/loginshield iframe... -->
                <!-- <v-row justify="center" v-show="loginWithLoginShield" class="ma-0 pt-5" style="width: 100%;">
                    <div id="loginshield-content" style="width: 100%; height: 600px;"></div>
                </v-row> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

/*
When user wants to navigate to a SaaS product's web application, the "sign in" button or link
redirects here with `product_id` in the query. The server will look up the corresponding product
to find the realm app tag and start a LoginFront SSO interaction with the realm app tag.
*/

export default {
    data: () => ({
        isViewReady: false,
        error: false,
    }),

    watch: {
        isAuthenticatedReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        focus() {
            this.init();
        },
    },

    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            user: (state) => state.user,
            session: (state) => state.session,
            focus: (state) => state.focus,
            brandprofile: (state) => state.brandprofile,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),

        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },

    methods: {
        async init() {
            try {
                console.log('Download.vue: init');
                this.$store.commit('loading', { startDownload: true });
                console.log('Download.vue: loading startDownload');
                this.resetErrors();
                console.log('Download.vue: resetErrors complete');

                // TODO: maybe instead of tag in the query, we should accept the product id in the query, and then we look up the tag; that way redirects are only possible for products that are appropriately configured, and for anything else we can show a helpful error message
                if (!this.$route.query.product_id || !this.$route.query.account_id || !this.$route.query.order_id) {
                    console.log('Download.vue: missing required parameters');
                    this.error = true;
                    this.isViewReady = true;
                    return;
                }

                console.log('Download.vue: start');
                const result = await this.$client.site(this.brandprofile).authn.startDownload({ product_id: this.$route.query.product_id, account_id: this.$route.query.account_id, order_id: this.$route.query.order_id });
                const { intent, intent_params: intentParams = {} } = result;
                console.log(`Download.vue init intent: ${intent} with params: ${JSON.stringify(intentParams)}`);
                if (intent === 'redirect' && intentParams.redirect) {
                    // use replace so that when user taps 'back' button from there, they won't
                    // end up being redirected again to where they just wanted to come back from
                    if (typeof window.location.replace === 'function') {
                        window.location.replace(intentParams.redirect);
                    } else {
                        // TODO: also show link for user to click
                        window.location.href = intentParams.redirect;
                    }
                    return;
                }
                if (intent === 'login') {
                    // TODO: redirect to login page with state to return here after login so user can access the downloads
                    console.log('TODO: login');
                    return;
                }

                // show the user an error message
                this.error = true;
            } catch (err) {
                console.error('start login failed', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { startDownload: false });
                this.isViewReady = true;
            }
        },
        resetErrors() {
            this.error = false;
        },
    },

    mounted() {
        console.log('Download.vue: mounted');
        this.init();
    },
};
</script>
